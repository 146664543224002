import styled from "styled-components";

export const SearchWrapper = styled.div`
  background-color: #12161c !important;
  background-image: url("https://bscscan.com/images/svg/components/abstract-shapes-20.svg?v=2");
  color: white;
  padding-top: 50px;
  padding-bottom: 100px;
`;

export const Search = styled.div`
  text-align: left;
  h4 {
    color: #fbda3c;
    font-weight: 600;
    margin: 15px 0;
  }
  .ads {
    margin: 15px 0;
    color: hsla(0, 0%, 100%, 0.7);
    img {
      width: 20px;
    }
    .title {
      font-weight: 600;
    }
    a {
      font-weight: 600;
      text-decoration: none;
      color: #3498db;
    }
  }
`;

export const InputSearch = styled.div`
  display: flex;
  select {
    height: 50px;
    border-radius: 5px 0 0 5px;
    width: 20%;
    text-align: center;
    font-size: 15px;
  }
  input {
    height: 50px;
    width: 72%;
  }
  button {
    border: 0.1px solid;
    background-color: #3498db;
    border-color: #3498db;
    height: 50px;
    width: 8%;
    border-radius: 0 5px 5px 0px;
  }
`;
