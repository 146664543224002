import APIs from "api";
import NotFound from "pages/not-found";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Overview from "./components/Overview";
import {
  ActionWrapper,
  TabsWrapper,
  TitleWrapper,
  TransactionDetailWrapper,
} from "./styles";

const View = () => {
  const { hash } = useParams();

  const [transaction, setTransaction] = useState(null);
  const [prices, setPrices] = useState({});

  const getPrices = async () => {
    try {
      let USD_VND = 25500;
      const resVzonex: any = await APIs.getPrices({});
      const resCanvanex: any = await APIs.getPricesCanvanex({});
      if (resCanvanex.prices) {
        USD_VND =
          resCanvanex.prices["usdt"]["vnd"] / resCanvanex.prices["usdt"]["usd"];
      }
      let _prices = {
        ...resCanvanex.prices,
      };
      if (resVzonex.prices) {
        Object.keys(resVzonex.prices).forEach((token) => {
          _prices = {
            ..._prices,
            [token]: {
              ...resVzonex.prices[token],
              usd: (resVzonex?.prices[token]?.vnd || 1) / USD_VND,
            },
          };
        });
      }
      setPrices(_prices);
    } catch (error) {}
  };

  const didMount = async () => {
    try {
      getPrices();
      const res: any = await APIs.getBlockByHash({
        params: {
          hash,
        },
      });
      if (res && res.transaction) {
        setTransaction(res.transaction);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (hash) {
      didMount();
    }
  }, [hash]);

  return !transaction ? (
    <NotFound />
  ) : (
    <TransactionDetailWrapper>
      <Container>
        <Card>
          <CardBody>
            {" "}
            <TitleWrapper>
              <Row>
                <Col lg={4} md={12}>
                  <h4>Transaction Details</h4>
                </Col>
                <Col lg={8} md={12}>
                  <ActionWrapper>
                    <Button>Buy</Button>
                    <Button>Exchange</Button>
                    <Button>Earn</Button>
                    <Button>Gaming</Button>
                  </ActionWrapper>
                </Col>
              </Row>
              <hr />
            </TitleWrapper>
            <TabsWrapper>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className="active"
                    onClick={function noRefCheck() {}}
                  >
                    Overview
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="" onClick={function noRefCheck() {}}>
                    Internal Txns
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="" onClick={function noRefCheck() {}}>
                    Logs (2)
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="" onClick={function noRefCheck() {}}>
                    Comments
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab="1">
                <TabPane tabId="1">
                  <Overview transaction={transaction} prices={prices} />
                </TabPane>
              </TabContent>
            </TabsWrapper>
          </CardBody>
        </Card>
      </Container>
    </TransactionDetailWrapper>
  );
};

export default View;
