import styled from "styled-components";

export const NavbarWrapper = styled.div`
  background: white !important;
  .navbar-brand {
    img {
      width: 30px;
    }
    font-weight: 700;
    color: #3A3F51;
  }

  .container {
  }
  .navbar {
    background: white !important;
  }
`;
