import styled from "styled-components";

export const HomeWrapper = styled.div`
  margin-top: -60px;
  img {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  .col-common {
    border-right: 1px solid #b4b1b1;
  }
`;

export const MediaWrapper = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  img {
    width: 1.75rem;
    height: 1.75rem;
  }
  .media-body {
    flex: 1;
    margin-left: 5px;
    h2 {
      font-size: 0.76563rem !important;
      color: #77838f !important;
      text-transform: uppercase !important;
      margin: 0;
    }
    a {
      text-decoration: none;
      color: #1e2022;
      font-size: 0.9375rem;
    }
  }
  .text-right {
    text-align: right;
    h2 {
      font-size: 0.76563rem !important;
      color: #77838f !important;
      text-transform: uppercase !important;
      margin: 0;
    }
    a {
      text-decoration: none;
      color: #1e2022;
      font-size: 0.9375rem;
    }
  }
`;

export const ChartWrapper = styled.div`
  text-align: center;
`;

export const BlockTransactionWrapper = styled.div`
  margin-top: 30px;
  hr {
    margin: 12px 0;
  }
  .card {
    .card-body {
      height: 400px;
      overflow: auto;
    }
    .card-title {
      margin: 0;
      text-align: left;
      font-weight: 700;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #a4a8ac;
      padding: 10px;
    }
    .card-footer {
      background: white;
      a {
        display: block;
        color: #3498db;
        background: rgba(52, 152, 219, 0.1);
        border-color: transparent;
        padding: 5px;
        border-radius: 6px;
        :hover {
          color: #fff;
          background: #3498db;
          box-shadow: 0 4px 11px rgb(52 152 219 / 35%);
        }
      }
    }
  }
`;

export const TxWrapper = styled.div`
  .transaction {
    display: flex;
    align-items: center;
    text-align: left;
    .avatar {
      width: 2.73438rem;
      height: 2.73438rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ececec;
      span {
        font-weight: 600;
      }
    }
    .tx-body {
      margin-left: 10px;
    }
  }

  .from-to {
    text-align: left;
    display: flex;
    align-items: center;
    .from,
    .to {
      span {
        font-weight: 600;
      }
    }
    .left {
      flex: 1;
    }
    .text-align {
      span {
        position: relative;
        padding-left: 1.15rem;
        letter-spacing: 0.8px;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        color: #576470;
        background-color: rgba(119, 131, 143, 0.1);
        font-size: 0.65625rem;
        line-height: 1.7;
        padding: 5px 15px;
        font-weight: 600;
        ::after {
          position: absolute;
          left: 0;
          top: 0;
          content: "";
          border-top: 0.7rem solid #1b1a1a00;
          border-bottom: 0.7rem solid #1c1b1b00;
          border-left: 0.7rem solid #fff;
        }
      }
    }
  }
`;
