import { Card, CardBody, Col, Row } from "reactstrap";
import { ChartWrapper, MediaWrapper } from "./styles";

const Stats = () => {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col xs={12} md={6} lg={4} className={"col-common"}>
            <MediaWrapper>
              <img src="/assets/images/logo.png" alt="" />
              <div className="media-body">
                <h2>VZX Price</h2>
                <a href="/">
                  $n/a
                  <span className="text-secondary"> @ n/a BTC</span>
                  <span className="text-danger small"> (0%)</span>
                </a>
              </div>
            </MediaWrapper>
            <hr />
            <MediaWrapper>
              <img
                src="https://bscscan.com/images/svg/icons/icon-8.svg"
                alt=""
              />
              <div className="media-body">
                <h2>BNB MARKET CAP ON BSCe</h2>
                <a href="/">
                  $n/a
                  <span className="text-danger small"> (0 VZX)</span>
                </a>
              </div>
            </MediaWrapper>
          </Col>

          <Col xs={12} md={6} lg={4} className={"col-common"}>
            <MediaWrapper>
              <img
                src="https://bscscan.com/images/svg/icons/icon-2-1.svg?v=1.3"
                alt=""
              />
              <div className="media-body">
                <h2>TRANSACTIONS</h2>
                <a href="/">
                  n/a M<span className="text-secondary small"> (n/a TPS)</span>
                </a>
              </div>
              <div className="text-right">
                <h2>Fee</h2>
                <a href="/">
                  n/a VZX <span>($n/a)</span>
                </a>
              </div>
            </MediaWrapper>
            <hr />
            <MediaWrapper>
              <img
                src="https://bscscan.com/images/svg/icons/icon-51.svg?v=1.6"
                alt=""
              />
              <div className="media-body">
                <h2>LATEST BLOCK</h2>
                <a href="/">
                  n/a
                  <span> (3.0s)</span>
                </a>
              </div>
              <div className="text-right">
                <h2>VOTING POWER</h2>
                <a href="/">0 VZX</a>
              </div>
            </MediaWrapper>
          </Col>

          <Col xs={12} md={6} lg={4}>
            <ChartWrapper>
              <p>VZX TRANSACTION HISTORY LAST 14 DAYS</p>
              <p className="coming-soon">Coming soon</p>
            </ChartWrapper>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Stats;
