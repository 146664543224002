import TimeAgo from "javascript-time-ago";
// English.
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);

export const convertTimeAgo = (timestamp: number) => {
  const timeAgo = new TimeAgo("en-US");
  const ago = new Date().getTime() - +timestamp;
  return timeAgo.format(Date.now() - ago);
};

export const convertTimestamptoDate = (timestamp: number) => {
  const date = new Date(timestamp);
  return date.toString();
};
