import { Col, Container, Row } from "reactstrap";
import { InputSearch, Search, SearchWrapper } from "./styles";
import { FaSearch } from "react-icons/fa";

const View = () => {
  return (
    <SearchWrapper>
      <Container>
        <Row>
          <Col lg={6} xs={12}>
            <Search>
              <h4>VZX Smart Chain Explorer</h4>
              <InputSearch>
                <select name="f">
                  <option value="0">All Filters</option>
                  <option value="1">Addresses</option>
                  <option value="2">Tokens</option>
                  <option value="3">Name Tags</option>
                  <option value="4">Labels</option>
                  <option value="5">Websites</option>
                </select>
                <input
                  id="txtSearchInput"
                  type="text"
                  placeholder="Search by Address / Txn Hash / Block / Token"
                  aria-describedby="button-header-search"
                  name="q"
                ></input>
                <button>
                  <FaSearch color="white" fontSize={18}/>
                </button>
              </InputSearch>{" "}
              <div className="ads">
                <span className="title">Sponsored: </span>
                <img src="/assets/images/logo.png" alt="" /> Vzonex - Join the
                world's largest crypto exchange.
                <a
                  href="https://vzonex.com/auth/register"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {" "}
                  Sign Up Now.
                </a>
              </div>
            </Search>
          </Col>
          <Col lg={6} xs={12}></Col>
        </Row>
      </Container>
    </SearchWrapper>
  );
};

export default View;
