import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import NotFound from "pages/not-found";
import Header from "layouts/header";
import TransactionDetails from "pages/blockchain/transactions/details";
import Footer from "layouts/footer";
import Search from "layouts/search";
import Transaction from "pages/blockchain/transactions";

function App() {
  return (
    <div className="App">
      <div className="Wrapper">
        {" "}
        <Header />
        <Search />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/txs" element={<Transaction />}></Route>
            <Route path="/tx/:hash" element={<TransactionDetails />}></Route>
            <Route path="*" element={<NotFound />} />
            {/* <Route path="/old-match">
            <Redirect to="/will-match" />
          </Route>
          <Route path="/will-match">
            <WillMatch />
          </Route>
          <Route path="*">
            <NoMatch />
          </Route> */}
          </Routes>
        </BrowserRouter>
        <div id="push"></div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
