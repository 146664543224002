import { request, parseErrorResponse } from "./request";

const getBlockByHash = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .get(`blocks/${req.params.hash}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getBlocks = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .get(`blocks/?${req.query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getPrices = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .get(
        `${process.env.REACT_APP_API_VZONEX}/cryptos/price-stock/?${req.query}`
      )
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getPricesCanvanex = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .get(
        `${process.env.REACT_APP_API_CANVANEX}/cryptos/price-stock/?${req.query}`
      )
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const APIs = {
  getBlockByHash,
  getBlocks,
  getPrices,
  getPricesCanvanex
};

export default APIs;
