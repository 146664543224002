import styled from "styled-components";

export const TransactionDetailWrapper = styled.div`
  margin-top: -60px;
`;

export const TitleWrapper = styled.div`
  padding: 15px 0;
  h4 {
    text-align: left;
  }
`;

export const ActionWrapper = styled.div`
  text-align: right;
  button {
    background: #3498db;
    padding: 3px 6px;
    margin: 5px;
    border-radius: 5px;
  }
`;

export const TabsWrapper = styled.div`
  background-color: white;
  .nav-item {
    a {
      font-weight: 600;
    }
  }
`;

export const OverviewWrapper = styled.div`
  overflow: hidden;
  background-color: white;
  hr {
    margin: 8px 0;
  }
  .item {
    padding: 10px 20px;
    text-align: left;
    p {
      margin: 0 !important;
    }
    .status {
      color: #00c9a7;
      background-color: rgba(0, 201, 167, 0.1);
      font-size: 0.76563rem;
      padding: 0.4rem 0.7rem;
    }
    .block,
    .from,
    .to {
      color: #3498db;
      text-decoration: none;
    }
    .value {
      .amount {
        background-color: rgba(119, 131, 143, 0.1);
        font-size: 0.74094rem;
        padding: 0.3rem 0.5rem;
      }
    }
    .method {
      background-color: rgba(119, 131, 143, 0.1);
      padding: 0.3rem 0.5rem;
    }
  }
`;
