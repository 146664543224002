import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import { NavbarWrapper } from "./styles";

const View = () => {
  return (
    <NavbarWrapper>
      <Container>
        <Navbar color="light" expand="md" light>
          <NavbarBrand href="/">
            {" "}
            <img src="/assets/images/logo.png" alt="" /> VZXSCAN
          </NavbarBrand>
          <NavbarToggler onClick={function noRefCheck() {}} />
          <Collapse navbar>
            <Nav className="me-auto" navbar>
              <NavItem>
                <NavLink href="/">Home</NavLink>
              </NavItem>
              <UncontrolledDropdown inNavbar nav>
                <DropdownToggle caret nav>
                  Blockchain
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>Top Account</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>
                    <a href="/txs">View Txns</a>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown inNavbar nav>
                <DropdownToggle caret nav>
                  Validators
                </DropdownToggle>
                <DropdownMenu right></DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown inNavbar nav>
                <DropdownToggle caret nav>
                  Tokens
                </DropdownToggle>
                <DropdownMenu right></DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown inNavbar nav>
                <DropdownToggle caret nav>
                  Resources
                </DropdownToggle>
                <DropdownMenu right></DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown inNavbar nav>
                <DropdownToggle caret nav>
                  More
                </DropdownToggle>
                <DropdownMenu right></DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </NavbarWrapper>
  );
};

export default View;
