import { Card, CardBody, Container } from "reactstrap";
import { TransactionsWrapper } from "./styles";

const View = () => {
  return (
    <TransactionsWrapper>
      <Container>
        <Card>
          <CardBody>Coming Soon</CardBody>
        </Card>
      </Container>
    </TransactionsWrapper>
  );
};

export default View;
