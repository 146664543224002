import styled from "styled-components";

export const FooterWrapper = styled.div`
  color: white;
  background-color: #12161c !important;
  background-image: url("https://bscscan.com/images/svg/components/abstract-shapes-20.svg?v=2");
  padding: 30px;
  li,
  p,
  h5,
  a {
    text-align: left;
  }
  @media screen and (max-width: 500px) {
    li,
    p,
    h5,
    a {
      text-align: center;
    }
  } ;
`;

export const ColumnChain = styled.div`
  .chain {
    display: flex;
    img {
      width: 25px;
      margin-right: 5px;
    }
    span {
      font-size: 1.09375rem;
      font-weight: 600;
    }
    margin-bottom: 15px;
  }
`;

export const Column = styled.div`
  ul {
    list-style: none;
    padding: 0 !important;
    li {
      padding: 7px 0;
      a {
        color: hsla(0, 0%, 100%, 0.7);
        text-decoration: none;
      }
    }
  }
`;

export const BuildWrapper = styled.div``;
