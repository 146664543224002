import { Card, CardBody, Container } from "reactstrap";
import { NotFoundWrapper } from "./styles";

const View = () => {
  return (
    <NotFoundWrapper>
      <Container>
        <Card>
          <CardBody>
            <img src="/assets/images/404.png" alt="" />
          </CardBody>
        </Card>
      </Container>
    </NotFoundWrapper>
  );
};

export default View;
