/* eslint-disable jsx-a11y/anchor-is-valid */
import APIs from "api";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { formatNumber } from "utils/number";
import { convertTimeAgo } from "utils/times";
import Stats from "./Stats";
import {
  BlockTransactionWrapper,
  ContentWrapper,
  HomeWrapper,
  TxWrapper,
} from "./styles";

const View = () => {
  const [transactions, setTransactions] = useState([]);

  const didMount = async () => {
    const res: any = await APIs.getBlocks({
      query: {},
    });
    if (res && res.transactions) {
      setTransactions(res.transactions);
    }
  };

  useEffect(() => {
    didMount();
  }, []);

  return (
    <HomeWrapper>
      <Container>
        <ContentWrapper>
          <Stats />
          <BlockTransactionWrapper>
            <Row>
              <Col xs={12} lg={6}>
                <Card>
                  <CardTitle>Latest Blocks</CardTitle>
                  <CardBody>
                    <p>Coming soon</p>
                  </CardBody>
                  <CardFooter>
                    <a href="">View all blocks</a>
                  </CardFooter>
                </Card>
              </Col>
              <Col xs={12} lg={6}>
                <Card>
                  <CardTitle>Latest Transactions</CardTitle>
                  <CardBody>
                    {transactions.map((transaction: any) => (
                      <>
                        {" "}
                        <TxWrapper>
                          <Row>
                            <Col xs={12} md={4}>
                              <div className="transaction">
                                <div className="avatar">
                                  <span>Tx</span>
                                </div>
                                <div className="tx-body">
                                  <a href={`/tx/${transaction.hash}`}>
                                    {transaction.hash.slice(0, 12)}...
                                  </a>
                                  <span className="d-none d-sm-block small text-secondary">
                                    {convertTimeAgo(transaction.timestamp)}
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col xs={12} md={8}>
                              <div className="from-to">
                                <div className="left">
                                  <div className="from">
                                    <span> From </span>
                                    <a href="">
                                      {" "}
                                      {transaction.data &&
                                        transaction.data.from.slice(0, 20)}
                                      ...
                                    </a>
                                  </div>
                                  <div className="to">
                                    <span>To</span>
                                    <a href="">
                                      {" "}
                                      {transaction.data &&
                                        transaction.data.to.slice(0, 20)}
                                      ...
                                    </a>
                                  </div>
                                </div>
                                <div className="text-align">
                                  <span
                                    className="u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap"
                                    data-toggle="tooltip"
                                    title=""
                                    data-original-title="Amount"
                                  >
                                    {transaction.data &&
                                      formatNumber(
                                        transaction.data.stock.amount
                                      )}{" "}
                                    {transaction.data &&
                                      transaction.data.stock.symbol.toUpperCase()}
                                  </span>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </TxWrapper>
                        <hr />
                      </>
                    ))}
                  </CardBody>
                  <CardFooter>
                    <a href="/txs">View all transactions</a>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </BlockTransactionWrapper>
        </ContentWrapper>
      </Container>
    </HomeWrapper>
  );
};

export default View;
