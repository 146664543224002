/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { BsQuestionCircle } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import { Col, Row } from "reactstrap";
import { convertTimeAgo, convertTimestamptoDate } from "utils/times";
import { OverviewWrapper } from "../styles";
import { formatNumber } from "utils/number";

const Overview = ({ transaction, prices }: any) => {
  const getPrice = (amount: number, stock: string) => {
    try {
      return formatNumber(+prices[stock]["usd"] * +amount, 1);
    } catch (error) {
      return formatNumber((1000 * +amount) / 25440, 1);
    }
  };

  const columns = [
    {
      title: "Transaction Hash",
      render: () => <span>{transaction.hash}</span>,
    },
    {
      title: "Status",
      render: () => (
        <span className="status">
          <FaCheckCircle />
          {" " + transaction.data.status}
        </span>
      ),
    },
    {
      title: "Block",
      render: () => (
        <a href="" className="block">
          {transaction.nonce}
        </a>
      ),
    },
    {
      title: "Timestamp",
      render: () => (
        <span className="timestamp">
          {convertTimeAgo(+transaction.timestamp)} (
          {convertTimestamptoDate(+transaction.timestamp)})
        </span>
      ),
    },
    {
      title: "From",
      render: () => <span className="from">{transaction.data.from}</span>,
    },
    {
      title: "To",
      render: () => <span className="to">{transaction.data.to}</span>,
    },
    {
      title: "Value",
      render: () => (
        <span className="value">
          <span className="amount">
            {" "}
            {transaction.data.stock.amount}{" "}
            {transaction.data.stock.symbol.toUpperCase()}
          </span>
          <span className="dolla">
            {" "}
            ($
            {getPrice(
              +transaction.data.stock.amount,
              transaction.data.stock.symbol
            )}
            )
          </span>
        </span>
      ),
    },
    {
      title: "Transaction Fee",
      render: () => (
        <p className="fee">
          <span className="amount">
            {" "}
            {transaction.data.fee.amount}{" "}
            {transaction.data.fee.symbol.toUpperCase()}
          </span>
          <span className="dolla">
            {" "}
            ($
            {getPrice(
              +transaction.data.fee.amount,
              transaction.data.fee.symbol
            )}
            )
          </span>
        </p>
      ),
    },
    {
      title: "Method",
      render: () => <span className="method">{transaction.data.method}</span>,
    },
  ];

  return (
    <OverviewWrapper>
      {columns.map((item, key) => (
        <div key={key}>
          <div className="item" key={key}>
            <Row>
              <Col lg={3} md={6} xs={12}>
                <p>
                  {" "}
                  <BsQuestionCircle /> {item.title}:
                </p>
              </Col>
              <Col lg={9} md={6} xs={12}>
                {item.render()}
              </Col>
            </Row>
          </div>
          <hr />
        </div>
      ))}
    </OverviewWrapper>
  );
};
export default Overview;
