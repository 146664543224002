import styled from "styled-components";

export const NotFoundWrapper = styled.div`
  margin-top: -60px;
  .card {
    img {
      width: 100%;
    }
  }
`;
