/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Container, Row } from "reactstrap";
import { ColumnChain, Column, FooterWrapper, BuildWrapper } from "./styles";

const View = () => {
  return (
    <FooterWrapper>
      <Container>
        <Row>
          <Col xs={12} md={6} lg={6}>
            <ColumnChain>
              <div className="chain">
                <img src="/assets/images/logo.png" alt="" />
                <span>Powered by VZX Chain</span>
              </div>
              <p>
                VzxScan is a Block Explorer and Analytics Platform for Vzonex
                Smart Chain
              </p>
            </ColumnChain>
          </Col>

          <Col xs={12} md={6} lg={2}>
            <Column>
              <h5>Company</h5>
              <hr />
              <ul>
                <li>
                  <a href="">Advertise</a>
                </li>
                <li>
                  <a href="">Delegate to VzxScan</a>
                </li>
                <li>
                  <a href="">Contact Us</a>
                </li>
                <li>
                  <a href="">Brand Assets</a>
                </li>
                <li>
                  <a href="">Terms of Service</a>
                </li>
              </ul>
            </Column>
          </Col>

          <Col xs={12} md={6} lg={2}>
            <Column>
              <h5>Community</h5>
              <hr />
              <ul>
                <li>
                  <a href="">API Documentation</a>
                </li>
                <li>
                  <a href="">Knowledge Base</a>
                </li>
                <li>
                  <a href="">Network Status</a>
                </li>
                <li>
                  <a href="">Learn VZX</a>
                </li>
                <li>
                  <a href="">Terms of Service</a>
                </li>
              </ul>
            </Column>
          </Col>

          <Col xs={12} md={6} lg={2}>
            <Column>
              <h5>Products</h5>
              <hr />
              <ul>
                <li>
                  <a href="">Developer APIs</a>
                </li>
                <li>
                  <a href="">Etherscan</a>
                </li>
                <li>
                  <a href="">Blockscan</a>
                </li>
                <li>
                  <a href="">VDBScan</a>
                </li>
                <li>
                  <a href="">Polygonscan</a>
                </li>
              </ul>
            </Column>
          </Col>
        </Row>
        <hr />
        <BuildWrapper>
          VzxScan © 2022 (VZX) | ⛏ Built by the same team behind{" "}
          <a href="">Vzonex</a> | <a>Donate</a>
        </BuildWrapper>
      </Container>
    </FooterWrapper>
  );
};
export default View;
